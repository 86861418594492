import { Box, Grid } from "@mui/material";
import Image from "next/image";
import CROSS from "../public/assets/images/whiteCross.webp";
import Banner from "public/assets/images/home/powerofFive.webp";
import { useEffect, useState } from "react";
import Utils from "../utils/Utils";
import { useRouter } from "next/router";

function AppPopup() {
  const router = useRouter();
  const [showPopup, setShowPopup] = useState(false);
  const handlePopupClose = () => {
    setShowPopup(false);
    document.body.style.overflow = "auto";
  };
  const handlePopupOpen = () => {
    setShowPopup(true);
    document.body.style.overflow = "hidden";
  };
  const openCTA = () => {
    window.open("https://gromo.co.in/PbcK", "_blank");
  };
  useEffect(() => {
    if (router.pathname === "/customer") {
      return;
    }
    if (Utils.getCookie("AppPopup") || router?.query["app-status"]) {
    } else {
      Utils.setCookies("AppPopup", "true", 3);
      setTimeout(() => {
        handlePopupOpen();
      }, 5000);
      setTimeout(() => {
        handlePopupClose();
      }, 15000);
    }
  }, []);
  return (
    <>
      {showPopup && router.pathname !== "/customer" ? (
        <>
          <Grid
            id="AppPopup"
            sx={{
              height: "100%",
              width: "100%",
              display: "grid",
              placeContent: "center",
              background: "#10101266",
              inset: 0,
              zIndex: 55,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Grid
              sx={{
                position: "relative",
                width: { xs: "90%", md: "60%" },
                margin: "0 auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: { xs: "300px", md: "400px" },
                }}
              >
                <Image
                  src={CROSS}
                  alt="cross grey"
                  height={32}
                  width={32}
                  onClick={handlePopupClose}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "12px",
                    right: "12px",
                  }}
                  loading="lazy"
                />
              </Box>
              <Image
                width={1000}
                height={1000}
                src={Banner}
                onClick={openCTA}
                alt="Power of Five"
                style={{
                  cursor: "pointer",
                  // width: "100%",
                  borderRadius: "16px",
                }}
                loading="lazy"
              />
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
}
export default AppPopup;
