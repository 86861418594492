
declare const window: Window &
  typeof globalThis & {
    moe: any;
    ga: any;
    ReactNativeWebView: any;
    scDK: any;
  };

  export default class {
    static getOS() {
      var uA = navigator.userAgent || navigator.vendor;
      if (
        (uA.includes("Mac") && "ontouchend" in document)
      )
        return "iOS";
  
      var i,
        os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
      for (i = 0; i < os.length; i++)
        if (new RegExp(os[i], "i").test(uA)) return os[i];
    }
    static setTitle(title: string) {
      document.title = title;
    }
    
    static trackEvent(event: string, data?: any) {
      try {
          const Moengage = window?.moe({
              app_id: "MALTTXFD391QLJ3UZNAD1ZH9",
              debug_logs: Number(0),
          });
          if (data && Object.keys(data).length) {
              Moengage.track_event(event, data);
              console.log("data", data)
          } else {
              Moengage.track_event(event);
          }
      } catch (error) {}
    }

    static setCookies(name: string, value: string, hours: number) {
      var expires = "";
      if (hours) {
        var date = new Date();
        date.setTime(date.getTime() + hours * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      globalThis.window.document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
  
    static getCookie(name: string) {
      var nameEQ = name + "=";
      var ca = globalThis?.window?.document?.cookie?.split(";");
      for (var i = 0; i < ca?.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
  
    static removeCookie(name: string) {
      globalThis.window.document.cookie =
        name + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
  
  }
  

  